/* eslint-disable react/no-unused-prop-types */
import React, { createRef, useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  number, string, oneOf, bool, arrayOf
} from 'prop-types';
import {
  arrayOf as arrayType,
  shape as shapeType,
  string as stringType,
  params,
  useDataModel
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Link } from '@one-thd/sui-atomic-components';
import KeyProductFeatureName from './KeyProductFeatureName';
import { KeyProductFeatureValue } from './KeyProductFeatureValue';

const KeyProductFeatures = (props) => {

  const {
    hideImage,
    hideForProductTypes,
    itemId,
    minfeatures,
    maxfeatures,
    displaySimilarLinks,
    twoColumnGrid,
    oneColumnGrid,
    simple,
    forceMobile,
  } = props;
  const { channel } = useContext(ExperienceContext);

  const getSortedValue = ({ attrGroupList = {} }) => {
    return (attrGroupList.specifications || [])
      .filter((attr) => attr.specName)
      .sort((entryName, compName) => {
        let nameval1 = entryName.specName.toUpperCase();
        let nameval2 = compName.specName.toUpperCase();
        if (nameval1 < nameval2) {
          return -1;
        }
        if (nameval1 > nameval2) {
          return 1;
        }
        return 0;
      });
  };

  const getKpfFromSpecifications = (product) => {
    const { specificationGroup = [] } = product || {};
    const kpfGroups = (specificationGroup || []).filter((group) => [
      'Dimensions',
      'Details'
    ].indexOf(group.specTitle) > -1)
      .map((attrGroupList) => {
        return getSortedValue({ attrGroupList });
      });
    if (!kpfGroups.length) {
      return [];
    }
    return kpfGroups.reduce(function (prev, curr) {
      return prev.concat(curr);
    });
  };

  const specBlock = (feature) => (
    <div className={'sui-flex ' + (simple ? 'sui-items-center' : 'sui-flex-col sui-gap-3')}>
      <KeyProductFeatureName name={feature.name} simple={simple} />
      <KeyProductFeatureValue value={feature.value} simple={simple} />
    </div>
  );

  const similarItemBlock = (feature) => {
    return (
      <div className="sui-flex sui-flex-col sui-gap-3">
        {specBlock(feature)}
        <Link
          href={feature.refinementUrl}
          data-testid="similar-items-link"
          variant={simple ? 'body-sm' : 'bady-base'}
        >See Similar Items
        </Link>
      </div>
    );
  };

  const keyProductFeatureSpecs = (featuresAttr) => (

    featuresAttr
      .slice(0, maxfeatures)
      .map((feature, index) => {

        return (
          <div
            key={index}
            className={simple ? 'sui-mb-1' : 'sui-py-6'}
          >
            {feature.refinementUrl && feature.value.length === 1 && displaySimilarLinks ? (
              <a
                className="hover:sui-no-underline"
                href={feature.refinementUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                {similarItemBlock(feature)}
              </a>
            ) : (
              <div className="sui-w-full">
                {specBlock(feature)}
              </div>
            )}
          </div>
        );
      })
  );
  const keyProductFeatureContents = ({ url, featuresAttr }) => {

    const specClasses = classNames(
      'sui-grid',
      simple ? 'sui-gap-2' : 'sui-gap-6',
      {
        'sui-grid-cols-1 md:sui-grid-cols-2 lg:sui-grid-cols-3': !oneColumnGrid && !twoColumnGrid,
        'sui-grid-cols-1 md:sui-grid-cols-1 lg:sui-grid-cols-1': oneColumnGrid,
        'sui-grid-cols-2 md:sui-grid-cols-2 lg:sui-grid-cols-2': twoColumnGrid,
      }
    );

    const defaultClasses = classNames('sui-flex sui-flex-col lg:sui-flex-row sui-w-full sui-gap-6 sui-overflow-hidden');
    const mobileClasses = classNames('sui-flex sui-flex-col sui-w-full sui-gap-6 sui-overflow-hidden');

    return (
      <div
        data-component="KeyProductFeatures"
        className={forceMobile ? mobileClasses : defaultClasses}
        data-testid={forceMobile ? 'kpf-mobile' : 'kpf-desktop'}
      >
        {url && (
          <div
            className="sui-flex sui-justify-center sui-items-center sui-min-w-fit"
          >
            <img
              className="sui-object-contain sui-w-96 lg:sui-w-80"
              src={(url || '')}
              alt="product"
              height="1"
              width="1"
            />
          </div>
        )}
        <div className="sui-grow">
          <div className={specClasses} data-testid="key-product-feature-list">
            {keyProductFeatureSpecs(featuresAttr)}
          </div>
        </div>
      </div>
    );
  };

  const filterAttributeDuplicates = ({ features, product }) => {
    const specAttributes = getKpfFromSpecifications(product)
      .map((spec) => ({
        name: spec.specName,
        value: spec.specValue
      }));
    const result = specAttributes.filter(
      (specNames) => features.find((specName) => specNames.name.includes(specName.name)));
    const specKpfValue = result.filter((specValues) => specValues.value.includes(','));
    return Object.values(features.reduce((filteredAttr, {
      name,
      refinementId,
      refinementUrl,
      value
    }) => {
      let filteredAttributes = filteredAttr;
      if (!filteredAttributes[name]?.modified) {
        filteredAttributes[name] = {
          name,
          refinementId,
          refinementUrl,
          value: [],
          modified: true
        };
      }
      filteredAttributes[name].value.push(value);
      specKpfValue.map((specs) => {
        if (specs.name === filteredAttributes[name].name) {
          filteredAttributes[name].value = specs.value && specs.value.split(',');
        }
        return filteredAttributes[name].value;
      });
      return filteredAttributes;
    }, {}));
  };

  const [kpfData, setKpfData] = useState({
    keyProductFeaturesItems: []
  });

  const { data } = useDataModel('product', {
    variables: { itemId }
  });

  const { product } = data || {};

  const { keyProductFeatures } = product || {};

  useEffect(() => {
    if (keyProductFeatures) {
      setKpfData({
        keyProductFeaturesItems: keyProductFeatures?.keyProductFeaturesItems
      });
    }
  }, [itemId, keyProductFeatures]);

  if (!keyProductFeatures?.keyProductFeaturesItems?.length && !kpfData.keyProductFeaturesItems.length) {
    return null;
  }
  const shouldHide = hideForProductTypes?.includes(product?.info?.productSubType?.name);

  if (shouldHide) {
    return null;
  }

  const keyProductFeaturesItems = keyProductFeatures?.keyProductFeaturesItems || kpfData.keyProductFeaturesItems;
  const features = keyProductFeaturesItems?.[0]?.features || [];

  let featuresAttr = filterAttributeDuplicates({ features, product });

  if (featuresAttr?.length < minfeatures && channel === 'mobile') {
    featuresAttr = getKpfFromSpecifications(product)
      .map((spec) => ({
        name: spec.specName,
        value: spec.specValue
      }));
  }

  if (featuresAttr?.length < minfeatures) {
    return null;
  }

  const url = hideImage ? null : product.media?.image?.url;
  return keyProductFeatureContents({ url, featuresAttr });
};

KeyProductFeatures.displayName = 'KeyProductFeatures';

const specs = arrayType(shapeType({
  specifications: arrayType(shapeType({
    specName: stringType(),
    specValue: stringType()
  })),
  specTitle: stringType()
}));

if (specs.skip) {
  specs.skip('skipSpecificationGroup', false);
}

const keyProductFeaturesModel = shapeType({
  keyProductFeaturesItems: arrayType(shapeType({
    features: arrayType(shapeType({
      name: stringType(),
      refinementId: stringType(),
      refinementUrl: stringType(),
      value: stringType()
    }))
  }))
});

if (keyProductFeaturesModel.skip) {
  keyProductFeaturesModel.skip('skipKPF', false);
}

KeyProductFeatures.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    dataSources: stringType(),
    keyProductFeatures: keyProductFeaturesModel,
    info: shapeType({
      productSubType: shapeType({
        name: stringType()
      })
    }),
    media: shapeType({
      image: shapeType({
        url: stringType()
      }).client(),
      images: arrayType(shapeType({
        url: stringType(),
        type: stringType(),
        subType: stringType(),
        sizes: arrayType(stringType())
      }))
    }),
    specificationGroup: specs
  })
};

KeyProductFeatures.propTypes = {
  storeId: string,
  /**
   * Display similar links
   */
  displaySimilarLinks: bool,
  // hide the kpf product image
  hideImage: bool,
  // hide kpf for these Product Types
  hideForProductTypes: arrayOf(string),
  /**
   */
  itemId: string.isRequired,
  /**
   * Max number of features to be displayed (between 1 and 8)
   */
  maxfeatures: oneOf([1, 2, 3, 4, 5, 6, 7, 8]),
  /**
   * If API returns less than minfeatures, component should not render
   */
  minfeatures: number,
  /**
   * Show two column version of component
   */
  twoColumnGrid: bool,
  /**
   * Show one column version of component
   */
  oneColumnGrid: bool,
  /**
   * Show simplified and smaller features
   */
  simple: bool,
  forceMobile: bool,
};

KeyProductFeatures.defaultProps = {
  displaySimilarLinks: false,
  hideImage: false,
  hideForProductTypes: [],
  maxfeatures: 8,
  minfeatures: 3,
  twoColumnGrid: false,
  oneColumnGrid: false,
  simple: false,
  storeId: null,
  forceMobile: false
};

export { KeyProductFeatures };
